import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import PartnerDetail from './PartnerDetail'
import { useRecoilState, useRecoilValue } from 'recoil'
import { partnershipsState, nextIdSelector } from './state'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#524763',
    width: '100%',
  },
})

const Partnerships = () => {
  const { card } = useStyles()
  const [partnerships, setPartnerships] = useRecoilState(partnershipsState)
  const nextId = useRecoilValue(nextIdSelector)

  return (
    <div style={{ marginRight: 8, marginLeft: 8, marginBottom: 16 }}>
      <Card className={card}>
        <CardContent>
          <Typography type='headline' component='h2' gutterBottom style={{ color: '#b8b0c6' }}>
            Partnerships
          </Typography>

          {partnerships.map(({ awardPv, id }, ind) => (
            <PartnerDetail key={id} awardPv={awardPv} id={id} ind={ind} />
          ))}

          <div>
            <Button raised color='primary' style={{ width: '100%', marginTop: '1rem' }} onClick={() => handleAddPartner(nextId)}>
              Add Partnership
              <Add style={{ marginLeft: 0 }} />
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )

  function handleAddPartner(newId) {
    const newPartnerships = [...partnerships, { id: newId, awardPv: 15000 }]
    setPartnerships(newPartnerships)
  }
}

export default Partnerships
