import React from 'react'

import Input from '@material-ui/core/Input'

import { useRecoilState } from 'recoil'
import { personalCircleState } from './state'
import { tls } from './utilities'
import { Checkbox } from '@material-ui/core'
import FormRow from './FormRow'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: { backgroundColor: '#524763', marginRight: 8, marginLeft: 8, marginBottom: 16 },
  cardContent: { display: 'flex', flexDirection: 'column', alignItems: 'stretch' },
  inputText: {
    color: 'rgba(255,255,255,0.8)',
    textAlign: 'right',
    alignItems: 'flex-end',
  },
  input: { width: '5rem', marginLeft: '.2rem', color: 'rgba(255,255,255,0.8)', textAlign: 'right' },
})

const PersonalCircle = () => {
  const [personalPv, setPersonalPv] = useRecoilState(personalCircleState)
  const { card, input, inputText, cardContent } = useStyles()

  return (
    <Card className={card}>
      <CardContent classes={{ root: cardContent }}>
        <FormRow label='Personal PV'>
          <Input
            placeholder='###'
            onChange={handlePersonalCircleChange}
            value={`${personalPv === null ? '' : tls(personalPv * 0.01)}`}
            className={input}
            classes={{ input: inputText }}
            endAdornment='&nbsp;PV'
          />
        </FormRow>

        <FormRow label='CVR Met'>
          <Checkbox style={{ alignSelf: 'flex-start', marginRight: 'auto' }} defaultChecked disabled />
        </FormRow>

        {/* <FormRow label='Retail Profit'>
          <Typography className={inputWrapper} align='left'>
            $
            <Input onChange={e => console.log(e.target.value)} value={200} className={input} />
          </Typography>
        </FormRow> */}
      </CardContent>
    </Card>
  )
  function handlePersonalCircleChange(e) {
    let num
    if (e.target.value === '') num = null
    else num = parseInt(e.target.value.replace(/,/g, '').replace(/ /g, ''), 10) * 100
    setPersonalPv(num)
  }
}
export default PersonalCircle
