import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const FormRow = ({ label = 'label', labelColumnWidth = 100, children }) => {
  return (
    <RowStyle>
      <LabelCol style={{ minWidth: labelColumnWidth, flex: 1 }}>
        <Typography style={{ color: 'rgba(255,255,255,.4)', fontWeight: '500' }}>{label}:</Typography>
      </LabelCol>

      <div style={{ flex: 1, display: 'flex', alignItems: 'flex-start' }}>{children}</div>
    </RowStyle>
  )
}

export default FormRow

const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
`

const LabelCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 12px;
  font-weight: 600;
`
