/* eslint-disable no-unused-vars */
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'

import { tls } from './utilities'
import Results from './Results'
import Partnerships from './Partnerships'
import PersonalCircle from './PersonalCircle'

import { useRecoilValue } from 'recoil'
import { totalIncomeSelector } from './state'
import { useCountUp } from 'react-countup'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingTop: 0,
    flexGrow: 1,
    padding: 20,
    minHeight: '100vh',
    maxWidth: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  input: {
    margin: theme.spacing.unit,
  },
  card: {
    backgroundColor: '#524763',
  },
  appbar: {
    width: '100vw',
    opacity: 0.8,
  },
}))

const Main = () => {
  const { root, card, appbar } = useStyles()
  const totalIncome = useRecoilValue(totalIncomeSelector)

  const { countUp, update, end } = useCountUp({
    start: 0,
    end: totalIncome,
    duration: 1,
    formattingFn: num => tls(num * 0.01, 2),
  })

  useEffect(() => {
    if (end !== totalIncome) {
      update(totalIncome)
    }
  }, [end, totalIncome, update])

  return (
    <div style={{ backgroundColor: '#191324', minWidth: 375 }}>
      <AppBar position='fixed' color='primary' className={appbar}>
        <Toolbar>
          <Typography
            type='title'
            color='inherit'
            style={{
              color: '#191324AA',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            align='center'
          >
            Monthly Income: ${countUp}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={root}>
        <Typography type='display1' gutterBottom align='center' style={{ paddingTop: 70, color: '#82d8d8CC', fontWeight: 300 }}>
          IBO Income Calculator
        </Typography>

        <Grid container spacing={24}>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PersonalCircle />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Results />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Partnerships />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Main
