export function calcBv(value) {
  return value * 2.9
}

export function pvToBracket(pv) {
  if (pv < 10000) return 0
  if (pv >= 10000 && pv < 30000) return 3
  if (pv >= 30000 && pv < 60000) return 6
  if (pv >= 60000 && pv < 100000) return 9
  if (pv >= 100000 && pv < 150000) return 12
  if (pv >= 150000 && pv < 250000) return 15
  if (pv >= 250000 && pv < 400000) return 18
  if (pv >= 400000 && pv < 600000) return 21
  if (pv >= 600000 && pv < 750000) return 23
  if (pv >= 750000 && pv < 1000000) return 25
  if (pv >= 1000000 && pv < 1250000) return 27
  if (pv >= 1250000 && pv < 1500000) return 29
  if (pv >= 1500000) return 31
  return 0
}

export function partnershipIncome(bonusBracket, partnerPoints) {
  const partnerBracket = pvToBracket(partnerPoints)
  let differential = 0
  if (partnerBracket >= 25) {
    differential = 6
  } else if (partnerBracket < 25) {
    differential = bonusBracket - +partnerBracket
  }
  const partnerBv = calcBv(partnerPoints)
  const bonusCheck = partnerBv * differential * 0.01
  return bonusCheck
}

export function personalIncome(groupPoints, personalPoints) {
  const personalBracket = pvToBracket(groupPoints)
  const personalBv = calcBv(personalPoints)
  const income = personalBracket * personalBv * 0.01
  return income
}

export function calcBonusIncome(bonuses) {
  let bonus = 0
  if (bonuses.qTwelve) {
    bonus += +1500000
  }
  return bonus
}

export function tls(numValue, digits) {
  return (numValue ?? 0).toLocaleString(undefined, { minimumFractionDigits: digits || 0, maximumFractionDigits: digits || 0 })
}
