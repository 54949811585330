import React from 'react'
import Main from './Main'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { RecoilRoot } from 'recoil'

const theme = createMuiTheme({
  palette: {
    secondary: {
      50: '#CF256D',
      100: '#CF256D',
      200: '#CF256D',
      300: '#CF256D',
      400: '#CF256D',
      500: '#CF256D',
      600: '#CF256D',
      700: '#CF256D',
      800: '#CF256D',
      900: '#CF256D',
      A100: '#CF256D',
      A200: '#CF256D',
      A400: '#CF256D',
      A700: '#CF256D',
      contrastDefaultColor: 'light',
    },
    primary: {
      50: '#82d8d8',
      100: '#82d8d8',
      200: '#82d8d8',
      300: '#82d8d8',
      400: '#82d8d8',
      500: '#82d8d8',
      600: '#82d8d8',
      700: '#82d8d8',
      800: '#82d8d8',
      900: '#82d8d8',
      A100: '#82d8d8',
      A200: '#82d8d8',
      A400: '#82d8d8',
      A700: '#82d8d8',
      contrastDefaultColor: 'light',
    },
    error: { main: '#CF256D' },
  },
  status: {
    danger: '#CF256D',
  },
  type: 'dark',
})

function App() {
  return (
    <RecoilRoot>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default App
