import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

import { tls } from './utilities'
import CardContent from '@material-ui/core/CardContent'
import { useRecoilValue } from 'recoil'
import {
  countPlatinumPartnershipsSelector,
  awardPvSelector,
  countNonPlatinumPartnershipsSelector,
  bonusBracketSelector,
  totalIncomeSelector,
} from './state'
import FormRow from './FormRow'

const Results = () => {
  const { root, card, result, cardFonts } = useStyles()
  const awardPv = useRecoilValue(awardPvSelector)
  const bonusBracket = useRecoilValue(bonusBracketSelector)
  const platinumPartnersCount = useRecoilValue(countPlatinumPartnershipsSelector)
  const nonPlatinumPartnersCount = useRecoilValue(countNonPlatinumPartnershipsSelector)
  const totalIncome = useRecoilValue(totalIncomeSelector)
  return (
    <div className={root}>
      <Card className={card}>
        <CardContent classes={{ root: cardFonts }}>
          <FormRow label='Annualized'>
            <Typography align='left' className={result}>
              ${tls(totalIncome * 0.01 * 12)}
            </Typography>
          </FormRow>

          <FormRow label='Partnerships'>
            <Typography align='left' className={result}>
              {nonPlatinumPartnersCount + platinumPartnersCount ?? '0'}
            </Typography>
          </FormRow>

          <FormRow label={platinumPartnersCount ? 'Side Volume' : 'Group PV'}>
            <Typography align='left' className={result}>
              {tls(awardPv * 0.01)} PV
            </Typography>
          </FormRow>

          <FormRow label='Bonus Bracket'>
            <Typography align='left' className={result}>
              {tls(bonusBracket)}%
            </Typography>
          </FormRow>

          <FormRow label='Platinum Legs'>
            <Typography align='left' className={result}>
              {platinumPartnersCount ?? 'none'}
            </Typography>
          </FormRow>
        </CardContent>
      </Card>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    width: '100%',
  },
  card: {
    backgroundColor: '#524763',
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 16,
  },
  label: {
    color: '#b8b0c699',
    fontWeight: 500,
  },
  result: {
    color: '#b8b0c6',
    fontWeight: 600,
    letterSpacing: '.5px',
  },
  cardFonts: {
    color: '#b8b0c6',
    fontWeight: 600,
    letterSpacing: '.5px',
  },
})

export default Results
