import { atom, selector } from 'recoil'
import { sumBy } from 'lodash'
import { partnershipIncome, pvToBracket } from './utilities'

export const personalCircleState = atom({
  key: 'personalCircleState',
  default: 15000,
})

export const partnershipsState = atom({
  key: 'partnershipsState',
  default: [],
})
export const retailProfitState = atom({
  key: 'retailProfitState',
  default: 0,
})

export const platinumPartnershipsSelector = selector({
  key: 'platinumPartnershipsSelector',
  get: ({ get }) => {
    const allPartnerships = get(partnershipsState)
    const filtered = [...allPartnerships].filter(p => p.awardPv >= 750000)
    return filtered
  },
})

export const nonPlatinumPartnershipsSelector = selector({
  key: 'nonPlatinumPartnershipsSelector',
  get: ({ get }) => {
    const allPartnerships = get(partnershipsState)
    const filtered = [...allPartnerships].filter(p => p.awardPv < 750000)
    return filtered
  },
})

export const awardPvSelector = selector({
  key: 'awardPvSelector',
  get: ({ get }) => {
    const nonPlatinumPartnerships = get(nonPlatinumPartnershipsSelector)
    const nonPlatinumPv = sumBy(nonPlatinumPartnerships, 'awardPv')
    const personalPv = get(personalCircleState)
    return (nonPlatinumPv ?? 0) + personalPv
  },
})

export const bonusBracketSelector = selector({
  key: 'bonusBracketSelector',
  get: ({ get }) => {
    const sideVolume = get(awardPvSelector)
    return pvToBracket(sideVolume ?? 0)
  },
})

export const personalIncomeSelector = selector({
  key: 'personalIncomeSelector',
  get: ({ get }) => {
    const bracket = get(bonusBracketSelector)
    const personalPv = get(personalCircleState)
    return bracket * personalPv * 0.01
  },
})
export const totalIncomeSelector = selector({
  key: 'totalIncomeSelector',
  get: ({ get }) => {
    const personalIncome = get(personalIncomeSelector)
    const bracket = get(bonusBracketSelector)
    const partnerships = get(partnershipsState)
    const groupIncome = sumBy(partnerships, o => partnershipIncome(bracket, o.awardPv))
    return groupIncome + personalIncome
  },
})

export const countPlatinumPartnershipsSelector = selector({
  key: 'countPlatinumPartnershipsSelector',
  get: ({ get }) => get(platinumPartnershipsSelector).length,
})
export const countNonPlatinumPartnershipsSelector = selector({
  key: 'countNonPlatinumPartnershipsSelector',
  get: ({ get }) => get(nonPlatinumPartnershipsSelector).length,
})

export const nextIdSelector = selector({
  key: 'nextIdSelector',
  get: ({ get }) => {
    const all = get(partnershipsState)
    if (all.length === 0) return 1
    const last = all[all.length - 1]
    return last.id + 1
  },
})
