import React from 'react'

import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { tls } from './utilities'
import { useRecoilState } from 'recoil'
import { partnershipsState } from './state'

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing.unit,
    width: '3.5rem',
  },
  button: {
    margin: theme.spacing.unit,
    opacity: 0.8,
  },
  inputText: {
    color: 'rgba(255,255,255,0.8)',
    textAlign: 'right',
    alignItems: 'flex-end',
  },
  labelText: {
    color: 'rgba(255,255,255,0.4)',
    fontWeight: '600',
  },
}))

const PartnerDetail = ({ awardPv, id, ind }) => {
  const checkedPoints = awardPv === null ? '' : tls(awardPv * 0.01)
  const [partnerships, setPartnerships] = useRecoilState(partnershipsState)
  const { input, button, labelText, inputText } = useStyles()
  return (
    <div>
      <Typography gutterBottom className={labelText}>
        {awardPv >= 750000 ? 'Platinum ' : ''}
        Leg {ind + +1} PV:
        <Input
          className={input}
          classes={{ input: inputText }}
          inputProps={{ 'aria-label': 'Leg Volume' }}
          onChange={handleChange}
          value={checkedPoints}
        />
        <Button fab mini dense color='accent' className={button} onClick={handleRemoval}>
          <DeleteForeverIcon color='error' />
        </Button>
      </Typography>
    </div>
  )
  function handleChange(e) {
    let num
    if (e.target.value === '') num = null
    else num = parseInt(e.target.value.replace(/,/g, ''), 10) * 100

    const newPartnerships = [...partnerships].map(item => (item.id === id ? { id, awardPv: num } : { ...item }))
    setPartnerships(newPartnerships)
  }
  function handleRemoval() {
    const newPartnerships = [...partnerships].filter(item => item.id !== id)
    setPartnerships(newPartnerships)
  }
}

export default PartnerDetail
